import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

const ActivatePage = () => {
  const data = useStaticQuery(graphql`
    query activateQuery {
      site {
        siteMetadata {
          app_url
        }
      }
    }
  `);

  React.useEffect(() => {
    window.location.href = `${data.site.siteMetadata.app_url}/activate`;
  }, [data.site.siteMetadata.app_url]);

  return <></>;
};

export default ActivatePage;
